import React, { FC, useCallback } from 'react';
import {
  Button,
  IconButton,
  Dialog,
  Typography,
  DialogContent,
  TextField,
} from '@material-ui/core';

import { useViewport } from '../../hooks/useViewport';
import { useStyles } from './styles';
import { Close } from '@material-ui/icons';
import Spacer from '../Spacer';
import { DeviceType } from '../../providers/ViewportProvider';
import { createRaffle } from '../../lib/actions/createRaffle';
import { useProgramApis } from '../../hooks/useProgramApis';
import { PublicKey } from '@solana/web3.js';
import { txHandler } from '../../lib/anchorUtils';

interface CreateRaffleModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateRaffleModal: FC<CreateRaffleModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { device } = useViewport();
  const classes = useStyles({ device });
  const { draffleClient } = useProgramApis();

  const handleClose = () => {
    setIsOpen(false);
  };

  // Execture function, submit form, clear data
  const handleRaffle = useCallback(
    async (e: any) => {
      e.preventDefault();
      let adjustedTicketCost = e.target.ticketCost.value * (1000000000);
      // Use handler to give nice toast message
      await txHandler(
        () =>
          createRaffle(
            draffleClient,
            draffleClient.provider.wallet.publicKey,
            new PublicKey('So11111111111111111111111111111111111111112'),
            adjustedTicketCost,
            e.target.maxEntrants.value,
            e.target.prize.value * 1000000000,
            e.target.title.value,
          ),
        `Vote created successfully!`
      );
      e.target.title.value = '';
      e.target.ticketCost.value = '';
      e.target.maxEntrants.value = '';
      e.target.prize.value = '';
      setIsOpen(false);
    },
    [draffleClient, setIsOpen]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      className={classes.modal}
    >
      <div>
        <div className={classes.header}>
          <IconButton size="small" onClick={() => setIsOpen(false)}>
            <Close />
          </IconButton>
        </div>
        <DialogContent>
          <form onSubmit={handleRaffle}>
            <Typography variant="overline">Vote Title (Maximum Length is 11)</Typography>
            <TextField
              margin="dense"
              id="title"
              type="text"
              fullWidth
              defaultValue={'Vote-0'}
              variant="standard"
            />
            <Spacer height={device === DeviceType.Phone ? '5px' : '10px'} />
            <Typography variant="overline">Vote Ticket Price (9 Decimals)</Typography>
            <TextField
              margin="dense"
              id="ticketCost"
              type="text"
              fullWidth
              defaultValue={'0.01'}
              variant="standard"
            />
            <Spacer height={device === DeviceType.Phone ? '5px' : '10px'} />
            <Typography variant="overline">Ticket Supply</Typography>
            <TextField
              margin="dense"
              id="maxEntrants"
              type="text"
              defaultValue={'1000'}
              fullWidth
              variant="standard"
            />
            <Spacer height={device === DeviceType.Phone ? '5px' : '10px'} />
            <Typography variant="overline">Prize (9 Decimals)</Typography>
            <TextField
              margin="dense"
              id="prize"
              type="text"
              fullWidth
              defaultValue={'1'}
              variant="standard"
            />
            <Spacer height={device === DeviceType.Phone ? '5px' : '10px'} />
            <Button type="submit" style={{ marginBottom: '1rem' }} variant='contained'>Create Vote</Button>
          </form>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default CreateRaffleModal;
