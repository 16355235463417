/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import RaffleCard from '../../components/RaffleCard';
import { useStyles } from './styles';
import { useRafflesStore } from '../../hooks/useRafflesStore';
import Screen from '../../components/layout/Screen';
import { Raffle } from '../../lib/types';
import { useViewport } from '../../hooks/useViewport';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { toEntrantsProcessed } from '../../lib/store';
import { expand } from '../../lib/randomnessTools';

const ExploreRafflesScreen: FC = () => {
  const { device } = useViewport();
  const classes = useStyles({ device });
  const { raffles, fetchAllRaffles, fetching } = useRafflesStore();

  useEffect(fetchAllRaffles, [fetchAllRaffles]);

  const filterMap = useMemo(
    () => ({
      ongoing: (raffle: Raffle) => !raffle.isEnded,
    }),
    []
  );

  const rafflesToShow = useMemo(() => {
    let toShow = [...raffles.values()].filter(filterMap.ongoing);
    return toShow;
  }, [raffles, filterMap]);

  if (raffles.size === 0 && fetching)
    return (
      <>
        <Typography variant="h1" className={classes.titleBar}>
          Live Raffles<br />
          Win Instant & Giveaways
        </Typography>
        <div className={classes.mainContent}>
          <CircularProgress color="secondary" />
        </div>
      </>
    );

  if (raffles.size === 0)
    return (
      <>
        <Typography variant="h1" className={classes.titleBar}>
          Live Raffles<br />
          Win Instant & Giveaways
        </Typography>
        <Typography variant="h4" className={classes.mainContent}>
          Raffles will be coming soon!
        </Typography>
      </>
    );

  return (
    <>
      <Typography variant="h1" className={classes.titleBar}>
        Live Raffles<br />
        Win Instant & Giveaways
      </Typography>
      {rafflesToShow.length > 0 ? (
        <Grid
          container
          justifyContent={'center'}
          direction={'row'}
          className={classes.rafflesGrid}
        >
          {rafflesToShow
            // .filter((r) => r.prizes.length > 0)
            // .reverse()
            .map((raffle) => {

              return (
                <RaffleCard
                  key={raffle.publicKey.toString()}
                  className={classes.raffleCardContainer}
                  raffle={raffle}
                />
              )
            })}
        </Grid>
      ) : (
        <Typography variant="h4" className={classes.mainContent}>
          No Live Raffles
        </Typography>
      )}
    </>
  );
};

const ExploreRafflesScreenWithLayout = () => {
  const { device } = useViewport();
  const classes = useStyles({ device });
  const { raffles, fetchAllRaffles, fetching } = useRafflesStore();

  useEffect(fetchAllRaffles, [fetchAllRaffles]);

  const filterMap = useMemo(
    () => ({
      ended: (raffle: Raffle) => raffle.isEnded,
    }),
    []
  );

  const rafflesToShow = useMemo(() => {
    let toShow = [...raffles.values()].filter(filterMap.ended).filter((raffle: Raffle) => raffle.entrants.size !== 0);
    return toShow;
  }, [raffles, filterMap]);

  console.log(rafflesToShow)
  const rafflesToShowHasEntrants = rafflesToShow.map((value) => {
    const keys = Array.from(value.entrants.keys()).map(key => String(key));
    let winner: any = []
    if (!(!value.randomness || !value.entrants || value.entrants.size === 0)) {
      const secret = value.randomness;

      const rand = expand(secret, 0);
      const winningTicket = rand % value.totalTickets
      winner.push(value.entrantsRaw[winningTicket].toBase58());
    }
    return {
      keys: keys.length !== 0 ? keys[0] : '',
      winner
    }
  })
  return (
    <Screen>
      <ExploreRafflesScreen />
      <div style={{ maxWidth: '360px', width: '100%' }}>
        <Typography variant="h1" className={classes.titleBar}>BIGGEST WINNERS</Typography>
        {
          rafflesToShowHasEntrants.length === 0 && fetching ? (
            <Typography variant="h4" className={classes.mainContent}>
              No Live Raffles
            </Typography>
          ) : (
            <Carousel centerMode infiniteLoop showIndicators={false} showThumbs={false} showStatus={false} autoFocus centerSlidePercentage={360}>
              {
                rafflesToShowHasEntrants.filter(raffle => raffle.keys !== '').map((raffle) => (
                  <div style={{ padding: '1rem' }}>
                    <img src="/winners/winner1.jpg" alt='' style={{ width: '280px', height: '280px', borderRadius: '1rem' }} />
                    {
                      raffle.winner.length !== 0 ? <Typography variant='h3' style={{ marginTop: '1rem' }}>{`${raffle.winner[0].slice(0, 4) + '....' + raffle.winner[0].slice(-4)}`}</Typography> : <Typography variant='h3' style={{ marginTop: '1rem' }}>{`None`}</Typography>
                    }

                  </div>
                ))
              }
            </Carousel>
          )
        }
      </div>
    </Screen>
  )
};

export default ExploreRafflesScreenWithLayout;
